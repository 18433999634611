import React from 'react';
import {Grid, Row, Col, FormControl, Button} from 'react-bootstrap';

class KeyValue extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    this.state = {
      keyVal: this.props.keyValue,
      valVal: this.props.valueValue
    };
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleKeyChange(e){
    this.setState({keyVal: e.target.value})
  }

  handleValueChange(e){
    this.setState({valVal: e.target.value})
  }

  render(){
    let keyVal = this.state.keyVal;
    let keyDesc = this.props.keyDescription || 'key';
    let valVal = this.state.valVal;
    let valueDesc = this.props.valueDescription || 'value';

    return (
      <Grid>
        <Row>
          <Col xs={5} sm={5} md={5} lg={5}>
            <FormControl
              value={keyVal}
              type="text"
              placeholder={keyDesc} />
          </Col>
          <Col xs={5} sm={5} md={5} lg={5}>
            <FormControl
              value={valVal}
              type="text"
              placeholder={valueDesc} />
          </Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            <Button style={{marginLeft: 2}} bsStyle="success" bsSize="sm"><i className="fa fa-check"/></Button>
            <Button style={{marginLeft: 2}} bsStyle="warning" bsSize="sm"><i className="fa fa-times-circle"/></Button>
            <Button style={{marginLeft: 2}} bsStyle="danger" bsSize="sm"><i className="fa fa-trash"/></Button>
          </Col>
        </Row>
      </Grid>
    )
  }

}

export default KeyValue
import React from 'react';
import { Link } from 'react-router';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import {LinkContainer, IndexLinkContainer} from 'react-router-bootstrap';

class LoggedInNav extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.router = context.router;
  }

  render(){
    return (
      <Nav pullRight onClick={this.navClick}>
        <IndexLinkContainer to="/">
          <NavItem eventKey={1}>Home</NavItem>
        </IndexLinkContainer>
        <LinkContainer to="/shows">
          <MenuItem>
            Shows
          </MenuItem>
        </LinkContainer>
        <LinkContainer to="/songs">
          <MenuItem>
            Songs
          </MenuItem>
        </LinkContainer>
        <NavDropdown eventKey={3} title={this.props.username} id="nav-dropdown">
          <LinkContainer to={`/users/edit/${this.props.userID}`}>
            <MenuItem eventKey="3.1">
              Edit Profile
            </MenuItem>
          </LinkContainer>
          <LinkContainer to="/signout">
            <MenuItem eventKey="3.2">
              Sign Out
            </MenuItem>
          </LinkContainer>
        </NavDropdown>
      </Nav>
    )
  }
}

export default LoggedInNav
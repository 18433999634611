import moment from 'moment';
import React from 'react';
import {Button, Label} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Well, Panel, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import {Link} from 'react-router';
import {LinkContainer} from 'react-router-bootstrap';

import SongTypeFilter from './songlist/SongTypeFilter.js'
import SongStore from './../../stores/SongStore.jsx';

import auth from './../../services/Authentication';

class ListSongsChild extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    this.state = {filter: this.props.filter};
  }
  componentWillUpdate(nextProps){
    if (nextProps.filter !== this.props.filter){
      this.setState({filter: nextProps.filter})
    }
  }
  filterSongs(){
    let songs = this.props.Songs;
    let filterString = this.state.filter;
    let filterFunction = null;

    switch(filterString) {

      case 'original':
        filterFunction = (song) => {return song && song.writtenBy && song.writtenBy.band.toLowerCase() === 'spafford' && !song.isJam};
        break;

      case 'cover':
        filterFunction = (song) => {return song && song.writtenBy && song.writtenBy.band.toLowerCase() !== 'spafford'};
        break;

      case 'jam':
        filterFunction = (song) => { return song && song.isJam };
        break;

      default:
        return songs;
    }
    return songs.filter(filterFunction);
  }

  renderSongFlags(Song){
    let flags = [];
    let flagStyle = {marginRight: 5};
    if (Song.writtenBy.band && Song.writtenBy.band.toLowerCase() === 'spafford' ){
      flags.push(<Label style={flagStyle} bsStyle="info">Original</Label>)
    }
    if (Song.writtenBy.band && Song.writtenBy.band.toLowerCase() !== 'spafford' ){
      let tooltip = <Tooltip id="tooltip">{Song.writtenBy.band}</Tooltip>;
      flags.push(<OverlayTrigger placement="left" overlay={tooltip}>
        <Label style={flagStyle} bsStyle="warning">Cover</Label>
      </OverlayTrigger>);
    }
    if (Song.lyrics){
      flags.push(<Label style={flagStyle} bsStyle="primary">Lyrics</Label>)
    }
    if (Song.isJam){
      flags.push(<Label style={flagStyle} bsStyle="danger">Jam</Label>)
    }

    return flags;
  }

  render() {
    let songs = this.filterSongs();
    let songRows = songs.map((Song) => {
      let linkToUrl = `/songs/${Song._id}`;
      let linkToEditUrl = `/songs/edit/${Song._id}`;
      let formattedFirstTimePlayedDate = Song.firstPlayed.date.substr(0,10);
      let firstTimePlayedLink = `/shows/${Song.firstPlayed.showId}`;
      return (
        <tr>
          {auth.loggedIn() &&
          <td>
            <LinkContainer to={linkToEditUrl}>
              <Button bsSize='xs' bsStyle="warning"><i className="fa fa-pencil-square" /></Button>
            </LinkContainer>
          </td>
          }
          <td><Link key={Song._id} to={linkToUrl}>{Song.name}</Link></td>
          <td><Link to={firstTimePlayedLink}>{formattedFirstTimePlayedDate}</Link></td>
          <td>{Song.firstPlayed.venue.name}</td>
          <td>{Song.firstPlayed.venue.city}</td>
          <td>{Song.firstPlayed.venue.state}</td>
          <td>
            {this.renderSongFlags(Song)}
          </td>
        </tr>
      );
    });


    let table = <Table striped condensed hover>
      <thead>
        <tr>
          {auth.loggedIn() &&
          <th></th>
          }
          <th>Song Name</th>
          <th>First Time Played</th>
          <th>Venue</th>
          <th>City</th>
          <th>State</th>
          <th>Flags</th>
        </tr>
      </thead>
      <tbody>
      {songRows}
      </tbody>
    </Table>;

    return (
      <div className="marginBottom">
        <Grid>
          <Row className="productList">
            <h1>{songs.length || '0'} Song{songs.length !== 1 ? 's' : ''}:
              { auth.loggedIn() &&
                <Link to='/songs/create'>
                  <Button bsStyle="success" style={{marginLeft: 10}} onClick={this.handleAddClick}><i
                    className="fa fa-plus-circle"/></Button>
                </Link>
              }
            </h1>
            <hr></hr>
            <SongTypeFilter active={this.state.filter} />
            <hr></hr>
            {table}
          </Row>
        </Grid>
      </div>
    );


  }

}
export default ListSongsChild;

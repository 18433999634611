import React from 'react';
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Panel,
  Pagination,
  Button,
  Well,
  Label,
  Table,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import KeyValue from './KeyValue.jsx';
import HeaderAddButton from './HeaderAddButton.jsx'

class ListEditor extends React.Component {
  constructor(props){
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.state = {
      edit: false,
      editKey: '',
      editValue: ''
    }
  }

  handleAddClick(e){
  }

  handleItemClick(key){
    this.setState({
      edit: true,
      editKey: key,
      editValue: this.props.objectToEdit[key]
    })
  }

  renderObject(object){
    let renderedRows = [];
    for(let prop in object){
      if (object.hasOwnProperty(prop)){
        renderedRows.push(<ListGroupItem key={prop} field={prop} onClick={this.handleItemClick.bind(null, prop)}>
          <strong>{prop}</strong>: {object[prop]}
          </ListGroupItem>);
      }
    }
    return (renderedRows);
  }

  render(){
    let label = this.props.label;
    let  items = this.props.objectToEdit;
    if (!items){
      return <div><HeaderAddButton label={label} handleAddClick={this.handleAddClick} /></div>
    }

    let renderedItems = this.renderObject(items);
    let isEditMode = this.state.edit;
    let editKey = this.state.editKey;
    let editValue = this.state.editValue;
    return(
    <div>
      <HeaderAddButton label={label} onClick={this.handleAddClick} />
      <ListGroup>
        {renderedItems}
      </ListGroup>
      { isEditMode
        ? <KeyValue keyValue={editKey} valueValue={editValue} />
        : ""}
    </div>
    )
  }
}

export default ListEditor
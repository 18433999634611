import moment from 'moment';
import React from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router';


class SongPlay extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render(){
    const jamChar = this.props.jamChar || ' > ';
    const noJamChar = this.props.noJamChar || ', ';

    let song = this.props.song;
    let songLink = `/songs/${song._id}`;
    let lastSong = this.props.last;
    let name = "";
    let jamOut = '';
    let nolink = this.props.nolink;

    if (song){
      name = song.name;
      if (! lastSong){
        jamOut = Array(song.jamOut + 1).join(jamChar) || noJamChar;
      }
    }

    if (nolink) {
      return <span>{name}{jamOut}</span>
    }

    return (
      <span><Link to={songLink}>{name}</Link>{jamOut}</span>
    )
  }
}


export default SongPlay;
import React from 'react';
import auth from './../../../services/Authentication';

import {Button, Label} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Well, Panel, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {Link} from 'react-router';

class UserAttendanceButton extends React.Component {
  constructor(props){
    super(props);
    let UserStore = this.props.UserStore;
    this.state = {user: UserStore.getUser()};

    this.handleShowAdd = this.handleShowAdd.bind(this);
    this.handleShowRemove = this.handleShowRemove.bind(this);

    this._onChange = this._onChange.bind(this);
  }
  componentWillMount() {
    let UserStore = this.props.UserStore;
    UserStore.onChange(this._onChange);
  }
  componentWillUnmount() {
    let UserStore = this.props.UserStore;
    UserStore.removeChangeListener(this._onChange);
  }
  _onChange() {
    let UserStore = this.props.UserStore;
    this.setState({user: UserStore.getUser()});
  }

  handleShowAdd(){
    let UserStore = this.props.UserStore;
    let user = this.state.user;
    let showId = this.props.showId;
    if (user){
      let shows = user.shows || [];
      shows.push(showId);
      user.shows = shows;
      UserStore.editUser({shows: user.shows}, auth.getUserId())
    }
  }
  handleShowRemove(){
    let UserStore = this.props.UserStore;
    let user = this.state.user;
    let showId = this.props.showId;
    if (user && user.shows){
      let shows = user.shows;
      let i = shows.indexOf(showId);
      if (i >= 0){
        shows.splice(i, 1);
        user.shows = shows;
        UserStore.editUser({shows: user.shows}, auth.getUserId())
      }
    }
  }
  render(){
    let showId = this.props.showId;
    let userShows = (this.state.user && this.state.user.shows) || [];
    let userAtShow = userShows.find((usersShow) => {return usersShow === showId});

    let buttonSize = this.props.bsSize || 'xs';
    let buttonStyleCheck = this.props.bsStyleChecked || 'link';
    let buttonStyle = this.props.bsStyle || 'link';
    let buttonClass = this.props.className || 'fa fa-square-o';
    let buttonClassChecked = this.props.classNameChecked || 'fa fa-square';

    let yourShowText = this.props.textChecked;
    let notYourShowText = this.props.textUnChecked;
    let iconStyle = {};
    if (yourShowText || notYourShowText){
      iconStyle.marginRight = 10;
    }

    if (userAtShow){
      return (
        <Button bsSize={buttonSize} bsStyle={buttonStyleCheck} onClick={this.handleShowRemove}><i style={iconStyle} className={buttonClassChecked} />{yourShowText}</Button>
      )
    }

    return (
      <div>
        <Button bsSize={buttonSize} bsStyle={buttonStyle} onClick={this.handleShowAdd}><i style={iconStyle} className={buttonClass} />{notYourShowText}</Button>
      </div>
    )
  }
}

export default UserAttendanceButton;
import React from 'react';
import ShowStore from './../../stores/ShowStore.jsx';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import auth from './../../services/Authentication';
import ViewShowChild from './ViewShowChild.jsx';
function getShow() {
  return { Show: ShowStore.getShow() };
}

class ViewShow extends React.Component {
  constructor(props, context) {
    super(props, context);
    ShowStore.fetchShow(props.params.id);
    this.state = {};
    this.state.Show = {};
    this.state.loggedIn = auth.loggedIn();
    this._onChange = this._onChange.bind(this);
    this.deleteShow = this.deleteShow.bind(this);
  }

  componentWillMount() {
    ShowStore.onChange(this._onChange);
  }

  componentWillUnmount() {
    ShowStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getShow());
  }

  deleteShow(ShowId) {
    ShowStore.deleteShow(ShowId, this.props.history);
  }

  render() {
    return (
    <ViewShowChild loggedIn={this.state.loggedIn} ShowId= {this.props.params.id}
       Show = {this.state.Show} deleteShow = {this.deleteShow} />
    );
  }
}

export default ViewShow;

import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Panel, Well, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import HeaderAddButton from '../../shared/HeaderAddButton.jsx';

class EditShowNotes extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditNoteKeyChange = this.handleEditNoteKeyChange.bind(this);
    this.handleEditNoteValueChange = this.handleEditNoteValueChange.bind(this);
    this.handleEditConfirm = this.handleEditConfirm.bind(this);
    this.handleEditDelete = this.handleEditDelete.bind(this);
    this.handleEditExistingNote = this.handleEditExistingNote.bind(this);

    this.state = {
      showNotes: this.props.notes,
      editNote: null
    }
  }

  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.notes) !== JSON.stringify(nextProps.notes))
    {
      this.setState({showNotes: nextProps.notes});
    }
  }

  handleAddClick() {
    this.setState({editNote: {key: '', value: ''}});
  }

  handleEditExistingNote(noteKey){
    let notes = this.state.showNotes;
    this.setState({editNote: {key: noteKey, value: notes[noteKey]}});
  }

  handleEditNoteKeyChange(e){
    let editNote = this.state.editNote;
    editNote.key = e.target.value;
    this.setState({editNote: editNote});
  }
  handleEditNoteValueChange(e){
    let editNote = this.state.editNote;
    editNote.value = e.target.value;
    this.setState({editNote: editNote});
  }

  handleEditConfirm(e){
    let showNotes = this.state.showNotes || {};
    let editNote = this.state.editNote;
    if (editNote.key && editNote.value){
      showNotes[editNote.key] = editNote.value;
    }
    this.setState({
      showNotes: showNotes,
      editNote: null
    });
    if (this.props.onChange){
      this.props.onChange(showNotes);
    }
  }
  handleEditDelete(e){
    let showNotes = this.state.showNotes;
    let editNote = this.state.editNote;
    if (showNotes && showNotes.hasOwnProperty(editNote.key)){
      delete showNotes[editNote.key];
    }
    this.setState({
      showNotes: showNotes,
      editNote: null
    });
    if (this.props.onChange){
      this.props.onChange(showNotes);
    }
  }


  renderNotes(){
    let showNotes = this.state.showNotes;
    let editNote = this.state.editNote;
    let rendered = [];
    for (let prop in showNotes){
      if (showNotes.hasOwnProperty(prop)){
        rendered.push(
          <tr key={prop}>
            <td><Button bsStyle="link" onClick={this.handleEditExistingNote.bind(null,prop)}>{prop}</Button></td>
            <td>{showNotes[prop]}</td>
            <td></td>
          </tr>
        )
      }
    }

    if (editNote){

      rendered.push(
        <tr key="editNote">
          <td><FormControl type="text" value={editNote.key} onChange={this.handleEditNoteKeyChange}/></td>
          <td><FormControl type="text" value={editNote.value} onChange={this.handleEditNoteValueChange}/></td>
          <td>
            <Button bsSize="small" bsStyle="success" style={{marginRight: 5}} onClick={this.handleEditConfirm}><i className="fa fa-check-circle"/></Button>
            <Button bsSize="small" bsStyle="danger" onClick={this.handleEditDelete}><i className="fa fa-trash"/></Button>
          </td>
        </tr>
      )
    }

    return rendered;
  }
  render(){
    let header = <HeaderAddButton label="Show Notes" onClick={this.handleAddClick} />;
    let renderedNotes = this.renderNotes();
    if (!renderedNotes.length){
    }
    return (
      <Panel header={header}>
        {renderedNotes.length
          ? <Table>
             <tbody>
             {renderedNotes}
             </tbody>
            </Table>
          : ''
        }
      </Panel>
    )
  }
}

export default EditShowNotes;



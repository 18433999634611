"use strict";
let {get, post, del, put} = require("./RestAPI_Helper.js");
import auth from './../services/Authentication';

function ShowStore() {

  let showList = {},
    changeListeners = [],
    show = {},
    error = '',
    showDeleted = 'false';

  function triggerListeners() {
    changeListeners.forEach(function(listener) {
      listener();
    })
  };
  function fetchShowList() {
    get("/api/shows").then((data) => {
      showList = data;
      triggerListeners();
    });
  }
  function fetchShow(id) {
    get(`api/shows/${id}`).then((data) => {
      show = data;
      triggerListeners();
    });
  };

  function addShow(show, history) {
    post("/api/shows", show).then((g) => {
      show._id = g._id;
      history.pushState(null, '/shows/' + g._id);
    }).catch((err) => {
      if (err.status == 401) {
        error = err.message;
        authCheck(history);
      }
    })
  }

  function editShow(show, id, history) {

    put(`api/shows/${id}`, show).then((data) => {
      show = data;
      triggerListeners();
      history.pushState(null, '/shows/' + data._id);
    }).catch((err) => {
      if (err.status == 401) {
        error = err.message;
        authCheck(history);
      }
    })
  }

  function deleteShow(id, history) {

    del(`api/shows/${id}`).then((g) => {
      showDeleted = 'true';
      triggerListeners();
      history.pushState(null, '/shows');
    }).catch((err) => {
      if (err.status == 401) {
        error = err.message;
        authCheck(history);
      }
    })
  }

  function getShowList() {
    return showList;
  };

  function getShowListSorted(sortField, sortAscending) {
    if (!(showList instanceof Array)) return showList;
    let sortAscendingFunction = function(a, b){
      if (a[sortField] < b[sortField])
        return -1;
      if (a[sortField] > b[sortField])
        return 1;
      return 0;
    };
    let sortDescendingFunction = function(a, b){
      if (a[sortField] < b[sortField])
        return 1;
      if (a[sortField] > b[sortField])
        return -1;
      return 0;
    };

    let sortFunction = sortAscending ? sortAscendingFunction : sortDescendingFunction;

    return showList.sort(sortFunction)

  };

  function getShow() {
    showDeleted = 'false';
    return show;
  };

  function ShowDeletionStatus() {
    return showDeleted;
  };

  function onChange(listener) {
    changeListeners.push(listener);
  }

  function removeChangeListener(listener) {
    var index = changeListeners.findIndex(i => i === listener);
    changeListeners.splice(index, 1);
  }
  function authCheck(history) {
    auth.logout();
    history.pushState(null, '/signin', {session: false});
  }

  function getError() {
    return error;
  };

  return {
    onChange: onChange,
    removeChangeListener: removeChangeListener,
    fetchShow: fetchShow,
    getError: getError,
    addShow: addShow,
    editShow: editShow,
    getShowList: getShowList,
    getShowListSorted: getShowListSorted,
    getShow: getShow,
    deleteShow: deleteShow,
    fetchShowList: fetchShowList,
    ShowDeletionStatus: ShowDeletionStatus
  }
}

module.exports = new ShowStore();

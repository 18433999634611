"use strict";
let {get, post, del, put} = require("./RestAPI_Helper.js");
import auth from './../services/Authentication';

function SongStore() {

  let songList = {},
    changeListeners = [],
    song = {},
    error = '',
    songDeleted = 'false';

  function triggerListeners() {
    changeListeners.forEach(function(listener) {
      listener();
    })
  };
  function fetchSongList() {
    get("/api/songs").then((data) => {
      songList = data;
      triggerListeners();
    });
  }
  function fetchSong(id) {
    get(`api/songs/${id}`).then((data) => {
      song = data;
      triggerListeners();
    });
  };

  function addSong(song, history) {
    post("/api/songs", song).then((g) => {
      song._id = g._id;
      history.pushState(null, '/songs/' + g._id);
    }).catch((err) => {
      if (err.status == 401) {
        error = err.message;
        authCheck(history);
      }
    })
  }

  function editSong(song, id, history) {
    put(`api/songs/${id}`, song).then((data) => {
      song = data;
      triggerListeners();
      history.pushState(null, '/songs/' + data._id);
    }).catch((err) => {
      if (err.status == 401) {
        error = err.message;
        authCheck(history);
      }
    })
  }

  function deleteSong(id, history) {

    del(`api/songs/${id}`).then((g) => {
      songDeleted = 'true';
      triggerListeners();
      history.pushState(null, '/songs');
    }).catch((err) => {
      if (err.status == 401) {
        error = err.message;
        authCheck(history);
      }
    })
  }

  function getSongList() {
    return songList;
  };

  function getSongListSorted(sortField) {
    if (!(songList instanceof Array)) return songList;
    let sortFunction = function(a, b){
      if (a[sortField] < b[sortField])
        return -1;
      if (a[sortField] > b[sortField])
        return 1;
      return 0;
    };
    return songList.sort(sortFunction)
  };

  function getSong() {
    songDeleted = 'false';
    return song;
  };

  function SongDeletionStatus() {
    return songDeleted;
  };

  function onChange(listener) {
    changeListeners.push(listener);
  }

  function removeChangeListener(listener) {
    var index = changeListeners.findIndex(i => i === listener);
    changeListeners.splice(index, 1);
  }
  function authCheck(history) {
    auth.logout();
    history.pushState(null, '/signin', {session: false});
  }

  function getError() {
    return error;
  };

  return {
    onChange: onChange,
    removeChangeListener: removeChangeListener,
    fetchSong: fetchSong,
    getError: getError,
    addSong: addSong,
    editSong: editSong,
    getSongList: getSongList,
    getSongListSorted: getSongListSorted,
    getSong: getSong,
    deleteSong: deleteSong,
    fetchSongList: fetchSongList,
    SongDeletionStatus: SongDeletionStatus
  }
}

module.exports = new SongStore();

import React from 'react';
import SongStore from './../../stores/SongStore.jsx';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import auth from './../../services/Authentication';
import ViewSongChild from './ViewSongChild.jsx';

function getSong() {
  return { Song: SongStore.getSong() };
}

class ViewSong extends React.Component {
  constructor(props, context) {
    super(props, context);
    SongStore.fetchSong(props.params.id);
    this.state = {};
    this.state.Song = {};
    this.state.loggedIn = auth.loggedIn();
    this._onChange = this._onChange.bind(this);
    this.deleteSong = this.deleteSong.bind(this);
  }

  componentWillMount() {
    SongStore.onChange(this._onChange);
  }

  componentWillUnmount() {
    SongStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getSong());
  }

  deleteSong(SongId) {
    SongStore.deleteSong(SongId, this.props.history);
  }

  render() {
    return (
    <ViewSongChild loggedIn={this.state.loggedIn} SongId= {this.props.params.id}
       Song = {this.state.Song} deleteSong = {this.deleteSong} />
    );
  }
}

export default ViewSong;

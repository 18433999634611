import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col, Well} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';

class BandMembers extends React.Component {
  constructor(props){
    super(props)
  }

  render(){
    let value = this.props.value;
    let onChange = this.props.onChange;
    return (
      <FormControl componentClass="select" placeholder="select" value={value}  onChange={onChange}>
        <option value="">-- Choose Rockstar --</option>
        <option value='Andrew "Red" Johnson'>Andrew "Red" Johnson</option>
        <option value="Brian Moss">Brian Moss</option>
        <option value="Jordan Fairless">Jordan Fairless</option>
        <option value="Nick Tkachyk">Nick Tkachyk</option>
      </FormControl>
    )
  }
}

export default BandMembers;
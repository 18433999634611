import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col, Well, Table, Panel} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import BandMembers from '../../shared/BandMembers.jsx';

class EditInstruments extends React.Component {
  constructor(props){
    super(props);

    this.handleLeadVocalChange = this.handleLeadVocalChange.bind(this);
    this.handleBackingVocalChange = this.handleBackingVocalChange .bind(this);
    this.handleGuitarChange = this.handleGuitarChange .bind(this);
    this.handleBassChange = this.handleBassChange .bind(this);
    this.handlePianoChange = this.handlePianoChange .bind(this);
    this.handleDrumsChange = this.handleDrumsChange .bind(this);

    let defaultInstruments = {
        "leadVocals" : "Brian Moss",
        "guitar" : "Brian Moss",
        "bass" : "Jordan Fairless",
        "piano" : "Andrew \"Red\" Johnson",
        "drums" : "Nick Tkachyk"
      };

      this.state = {
        instruments: this.props.instruments || defaultInstruments,
        isEdit: true//this.props.isEdit
      };
      console.log(this.state);
  }
  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.instruments) !== JSON.stringify(nextProps.instruments)){
      this.setState({instruments: nextProps.instruments})
    }
  }

  handleLeadVocalChange(e){
    let instruments = this.state.instruments;
    instruments.leadVocals = e.target.value;
    this.setState({instruments: instruments});
    if (this.props.onChange){
      this.props.onChange(instruments);
    }
  }
  handleBackingVocalChange(e){
    let instruments = this.state.instruments;
    instruments.backingVocals = e.target.value;
    this.setState({instruments: instruments});
    if (this.props.onChange){
      this.props.onChange(instruments);
    }
  }
  handleGuitarChange(e){
    let instruments = this.state.instruments;
    instruments.guitar = e.target.value;
    this.setState({instruments: instruments});
    if (this.props.onChange){
      this.props.onChange(instruments);
    }
  }
  handleBassChange(e){
    let instruments = this.state.instruments;
    instruments.bass = e.target.value;
    this.setState({instruments: instruments});
    if (this.props.onChange){
      this.props.onChange(instruments);
    }
  }
  handlePianoChange(e){
    let instruments = this.state.instruments;
    instruments.piano = e.target.value;
    this.setState({instruments: instruments});
    if (this.props.onChange){
      this.props.onChange(instruments);
    }
  }
  handleDrumsChange(e){
    let instruments = this.state.instruments;
    instruments.drums = e.target.value;
    this.setState({instruments: instruments});
    if (this.props.onChange){
      this.props.onChange(instruments);
    }
  }
  renderEditOrDisplay(field, handler){
    let instruments = this.state.instruments;
    if (! this.state.isEdit){
      return <span>{instruments[field]}</span>
    }
    return <BandMembers value={instruments[field]} onChange={handler}/>
  }

  render(){
    let instruments = this.state.instruments;
    if (!instruments) return <div></div>;
    return (
      <Panel header={<h4>Instruments</h4>}>
      <Table condensed>
        <thead>
        <tr>
          <th>Instrument</th>
          <th>Player</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Lead Vocals</td>
          <td>{this.renderEditOrDisplay('leadVocals', this.handleLeadVocalChange)}</td>
        </tr>
        <tr>
          <td>Backing Vocals</td>
          <td>{instruments.backingVocals && instruments.backingVocals.map((singer)=> <div>{singer}</div>)}</td>
        </tr>
        <tr>
          <td>Guitar</td>
          <td>{this.renderEditOrDisplay('guitar', this.handleGuitarChange)}</td>
        </tr>
        <tr>
          <td>Piano</td>
          <td>{this.renderEditOrDisplay('piano', this.handlePianoChange)}</td>
        </tr>
        <tr>
          <td>Bass</td>
          <td>{this.renderEditOrDisplay('bass', this.handleBassChange)}</td>
        </tr>
        <tr>
          <td>Drums</td>
          <td>{this.renderEditOrDisplay('drums', this.handleDrumsChange)}</td>
        </tr>
        </tbody>
      </Table>
    </Panel>
    );

  }
}

export default EditInstruments;
import React from 'react';
import DateTime from 'react-datetime';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Panel, Well, Table} from 'react-bootstrap';
import {Form, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';

class EditStartEndTimes extends React.Component {
  constructor(props){
    super(props);

    this.handleStartTimeChanged = this.handleStartTimeChanged.bind(this);
    this.handleEndTimeChanged = this.handleEndTimeChanged.bind(this);
    this.handleMultiShowIndexChanged = this.handleMultiShowIndexChanged.bind(this);


    this.state = {
      show: this.props.show
    };
  }

  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)){
      this.setState({show: nextProps.show});
    }
  }

  handleStartTimeChanged(value, formattedValue) {
     if (typeof value !== 'string'){
      let show = this.state.show;
      show.startTime = value.toISOString();
      this.setState({show: show});
      if (this.props.onChange){
        this.props.onChange(show);
      }
    }
  }

  handleEndTimeChanged(value, formattedValue) {
    if (typeof value !== 'string') {
      let show = this.state.show;
      show.endTime = value.toISOString();
      this.setState({show: show});
      if (this.props.onChange) {
        this.props.onChange(show);
      }
    }
  }


  handleMultiShowIndexChanged(e){
    let show = this.state.show;
    show.multiShowIndex = e.target.value || 0;
    show.date = `${show.date.substr(0,10)}T00:00:00.00${show.multiShowIndex}Z`;
    this.setState({show: show});
    if (this.props.onChange){
      this.props.onChange(show);
    }
  }

  render(){
    let show = this.state.show;
    let timezone = (new Date()).toTimeString();
    let defaultStartDate = new Date(show.date ? show.date.substr(0,10) : new Date().toISOString().substr(0,10));
    defaultStartDate.setDate(defaultStartDate.getDate() + 1);
    let defaultEndDate = new Date(defaultStartDate.toISOString());
    defaultEndDate.setHours(defaultEndDate.getHours() + 7);

    let defaultStartDateString = defaultStartDate.toISOString();
    let defaultEndDateString = defaultEndDate.toISOString();


    timezone = timezone.substr(timezone.indexOf("("));
    return (
    <Panel header={<h4>Start/End Time/MultiShow</h4>}>
        <p>Start/End time controls when the Twitter Bot listens, and when the Mixlr Streams display. If there are 2 shows on the same day, you'll need to make sure that this range is correct. If not, just consider a range that is wide enough to cover the start and end with enough padding</p>
      <Row>
        <FormGroup controlId="startTime">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Start Time:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Start Time:</ControlLabel></Col>
          <Col sm={6}>
            <DateTime
              utc={false}
              value={
                show.startTime
                  ? new Date(show.startTime)
                  : new Date(defaultStartDateString)
              }
              onChange={this.handleStartTimeChanged}/>
          </Col>
          <Col xsHidden={true} sm={4} className="text-left"><ControlLabel>{timezone}</ControlLabel></Col>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup controlId="endTime">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>End Time:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>End Time:</ControlLabel></Col>
          <Col sm={6}>
            <DateTime
              utc={false}
              value={
                show.endTime
                  ? new Date(show.endTime)
                  : new Date(defaultEndDateString)
              }
              onChange={this.handleEndTimeChanged}/>
          </Col>
          <Col xsHidden={true} sm={4} className="text-left"><ControlLabel>{timezone}</ControlLabel></Col>
        </FormGroup>
      </Row>

      <Row>
        <FormGroup controlId="multiShowIndex">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Multi Show Index:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Multi Show Index:</ControlLabel></Col>
          <Col sm={6}>
            <FormControl componentClass="select" placeholder="select"
                         value={show.multiShowIndex}
                         onChange={this.handleMultiShowIndexChanged}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </FormControl>
          </Col>
        </FormGroup>
      </Row>
    </Panel>

    );
  }
}

export default EditStartEndTimes;
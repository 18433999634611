import React from 'react';
import {Button, Label} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Well, Panel, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {ListGroup, ListGroupItem} from 'react-bootstrap';

import HeaderAddButton from './HeaderAddButton.jsx';

/// Props:
///   data: []
///    the data to render in the list.
///   header: String
///    the header for the panel
///   onChange: function(changedData)
///    a handler function which takes an array
///    changedData is the modified this.props.data
class ListGroupEditor extends React.Component {
  constructor(props){
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleAcceptNew = this.handleAcceptNew.bind(this);
    this.handleRemove = this.handleRemove.bind(this);

    this.state = {
      data: this.props.data || [] ,
      adding: false,
      addingText: '',
    }
  }

  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)){
      this.setState({data: nextProps.data})
    }
  }

  handleAdd(e){
    if(!this.state.adding){
      this.setState({
        adding: true,
        addingText: ''
      });
    }
  }

  handleAcceptNew(e){
    let data = this.state.data;
    let newData = this.state.addingText;
    if (data && data.push){
      data.push(newData);
      this.setState({
        data: data,
        adding: false,
        addingText:''})
    }
    if (this.props.onChange){
      this.props.onChange(data);
    }
  }
  handleRemove(indexToRemove){
    let data = this.state.data;
    if (data && data.splice){
      data.splice(indexToRemove, 1);
      this.setState({data: data});
    }
    if (this.props.onChange){
      this.props.onChange(data);
    }
  }

  render(){
    let header = this.props.header;
    let data = this.state.data;
    let adding = this.state.adding;
    let addingText = this.state.addingText;

    let removeStyle = {marginLeft: 20};
    return (
      <Panel header={<HeaderAddButton label={header} onClick={this.handleAdd} />}>
        <ListGroup>
          {data && data.map && data.map((item, n) =>
            <ListGroupItem key={item}>
              {item}<Button bsSize='xsmall' bsStyle="danger" style={removeStyle} onClick={this.handleRemove.bind(null, n)}><i className="fa fa-trash" /></Button>
            </ListGroupItem>
          )}
          {adding &&
          <ListGroupItem>
            <Well>
            <Grid fluid>
              <Row>
                <Col xs={8} sm={8} lg={8}>
                  <FormControl
                    autoFocus
                    type="text"
                    value={addingText}
                    onChange={(e) => this.setState({addingText: e.target.value})}
                  />
                </Col>
                <Col xs={4} sm={4} lg={4}>
                  <Button style={{marginRight: 5}}bsSize='small' bsStyle='success' onClick={this.handleAcceptNew}>
                      <i className="fa fa-check-circle" />
                  </Button>
                  <Button bsSize='small' bsStyle='warning' onClick={() => this.setState({adding: false, addingText: ''})}>
                    <i className="fa fa-times-circle" />
                  </Button>
                </Col>
              </Row>
            </Grid>
            </Well>
          </ListGroupItem>
          }
        </ListGroup>
      </Panel>
    )
  }
}

export default ListGroupEditor;
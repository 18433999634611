import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col, Well} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import EditSongList from './EditSongList.jsx'
import SongPlay from '../showview/SongPlay.jsx'


class EditSet extends React.Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.handleSetNameChange = this.handleSetNameChange.bind(this);
    this.handleRemoveSetClick = this.handleRemoveSetClick.bind(this);

    this.state = {
      showModal: false,
      set: this.props.set
    };
  }

  close(){
    this.setState({ showModal: false });
  }

  open(){
    this.setState({ showModal: true });
  }

  handleSetNameChange(e){
    let set = this.state.set;
    set.name = e.target.value;
    this.setState({set: set})
  }
  handleRemoveSetClick(e){
    e.preventDefault();
    if (this.props.onRemoveSetClick){
      this.props.onRemoveSetClick(this.state.set);
    }
  }


  renderDeleteButton(){
    if (!this.state.set.songs.length){
      return <Button bsSize="xs" bsStyle="danger" style={{marginRight: 5}} onClick={this.handleRemoveSetClick}><i className="fa fa-trash" /></Button>;
    }
  }
  render(){
    let set = this.state.set;
    let songs = this.props.songs;
    let songDisplay = [];
    if (set && set.songs){
      songDisplay = set.songs.map((song, n, songs) => {
        return <SongPlay key={song._id + n} song={song} last={songs.length -1 === n} nolink />
      });
    }

    return (
      <div>
        <Button bsSize="xs" bsStyle="info" style={{marginRight: 5}} onClick={this.open}>{set.name}:</Button>
        {this.renderDeleteButton()}
        {songDisplay}
        <Modal show={this.state.showModal} onHide={this.close}>
          <ModalHeader closeButton>
            <ModalTitle>
              <FormGroup>
                <ControlLabel>Set Name:</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={set.name}  onChange={this.handleSetNameChange}>
                  <option value="Set 1">Set 1</option>
                  <option value="Set 2">Set 2</option>
                  <option value="Set 3">Set 3</option>
                  <option value="Set 4">Set 4</option>
                  <option value="Encore 1">Encore 1</option>
                  <option value="Encore 2">Encore 2</option>
                  <option value="Soundcheck 1">Soundcheck 1</option>
                  <option value="Soundcheck 2">Soundcheck 2</option>
                </FormControl>
              </FormGroup>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <h4>Songs</h4>
            <EditSongList songs={set.songs} allSongs={songs} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.close}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
      )
  }

}

export default EditSet
/**
 * Created by damian on 4/10/17.
 */
import React from 'react';
import {Button, Label} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Well, Panel, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {Link} from 'react-router';

class SongTypeFilter extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      active: this.props.active || 'all'
    }
  }

  render(){
    let buttonStyle = {marginLeft: 10};
    let buttonSize = '';
    let active = this.state.active || '';
    return (
      <div>
        <Link to="/songs"><Button bsStyle="success" bsSize={buttonSize} className={active === 'all' ? 'active' : ''} >All</Button></Link>
        <Link to="/songs/" query={{filter: 'original'}}><Button bsStyle="info" bsSize={buttonSize} className={active === 'original' ? 'active' : ''} style={buttonStyle}>Original</Button></Link>
        <Link to="/songs/" query={{filter: 'cover'}}><Button bsStyle="warning" bsSize={buttonSize} className={active === 'cover' ? 'active' : ''} style={buttonStyle}>Cover</Button></Link>
        <Link to="/songs/" query={{filter: 'jam'}}><Button bsStyle="danger" bsSize={buttonSize} className={active === 'jam' ? 'active' : ''} style={buttonStyle}>Jam</Button></Link>
      </div>
    )
  }
}

export default SongTypeFilter;
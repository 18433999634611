import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Panel, Well, Table} from 'react-bootstrap';
import {Form, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';

class EditVenue extends React.Component {
  constructor(props){
    super(props);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);

    let newVenue = {
      name: '',
      city: '',
      state: '',
      country: ''
    };

    this.state = {
      venue: this.props.venue || newVenue
    }
  }

  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.venue) !== JSON.stringify(nextProps.venue)){
      this.setState({venue: nextProps.venue});
    }
  }

  handleNameChange(e){
    let venue = this.state.venue;
    venue.name = e.target.value;
    this.setState({venue: venue});
    if (this.props.onChange){
      this.props.onChange(venue);
    }
  }
  handleCityChange(e){
    let venue = this.state.venue;
    venue.city = e.target.value;
    this.setState({venue: venue});
    if (this.props.onChange){
      this.props.onChange(venue);
    }
  }
  handleStateChange(e){
    let venue = this.state.venue;
    venue.state = e.target.value;
    this.setState({venue: venue});
    if (this.props.onChange){
      this.props.onChange(venue);
    }
  }
  handleCountryChange(e){
    let venue = this.state.venue;
    venue.country = e.target.value;
    this.setState({venue: venue});
    if (this.props.onChange){
      this.props.onChange(venue);
    }
  }

  render(){
    let venue = this.state.venue;
    return (
    <Panel header={<h4>Venue</h4>}>
        <FormGroup controlId="venueName">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Name:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Name:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl
              id="venueName"
              type="text"
              label="Venue Name"
              value={venue.name}
              onChange={this.handleNameChange}
              placeholder="Enter Venue Name" />
          </Col>
        </FormGroup>

        <FormGroup controlId="venueCity">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>City:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>City:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl
              id="venueCity"
              type="text"
              label="City"
              value={venue.city}
              onChange={this.handleCityChange}
              placeholder="Enter Venue City" />
          </Col>
        </FormGroup>
        <FormGroup controlId="venueState">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>State:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>State:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl
              id="venueState"
              type="text"
              label="Venue State"
              value={venue.state}
              onChange={this.handleStateChange}
              placeholder="Enter Venue State" />
          </Col>
        </FormGroup>
        <FormGroup controlId="venueCountry">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Country:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Country:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl
              id="venueCountry"
              type="text"
              label="Country"
              value={venue.country}
              onChange={this.handleCountryChange}
              placeholder="Enter Venue Country" />
          </Col>
        </FormGroup>
    </Panel>

    );
  }
}

export default EditVenue;
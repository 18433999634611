import React from 'react';
import { Link } from 'react-router';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import {LinkContainer, IndexLinkContainer} from 'react-router-bootstrap';

class LoggedOutNav extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      allowSignIn: false
    };
    this.router = context.router;
  }

  render(){
    return (
      <Nav pullRight onClick={this.navClick}>
        <IndexLinkContainer to="/">
          <NavItem eventKey={1}>Home</NavItem>
        </IndexLinkContainer>
        <LinkContainer to="/shows">
          <MenuItem>
            Shows
          </MenuItem>
        </LinkContainer>
        <LinkContainer to="/songs">
          <MenuItem>
            Songs
          </MenuItem>
        </LinkContainer>
        <LinkContainer to="/signin">
          <MenuItem>
            Sign In
          </MenuItem>
        </LinkContainer>
        { this.state.allowSignIn &&
          <LinkContainer to="/signup">
            <MenuItem>
              Sign Up
            </MenuItem>
          </LinkContainer>
        }
      </Nav>
    )
  }
}

export default LoggedOutNav
import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Panel, Well} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import HeaderAddButton from '../../shared/HeaderAddButton.jsx'
import EditSetModal from './EditSetModal.jsx'


class EditSets extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveSetClick = this.handleRemoveSetClick.bind(this);
    this.state = {
      show: this.props.show,
      songs: this.props.songs
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.show._id !== this.props.show._id){
      this.setState({show: nextProps.show})
    }
    if (nextProps.songs.length !== this.props.songs.length){
      this.setState({songs: nextProps.songs})
    }
  }


  handleAddClick(e){
    let show = this.state.show;
    if (!show.sets){
      show.sets = [];
    }
    let newSet = {
      songs: []
    };

    // setup the new set name
    if (show.sets.length === 0){
      newSet.name = "Set 1";
    } else {
      // try to figure out what the next set should be named
      let splitLastSetName = show.sets[show.sets.length -1].name.split(' ');
      newSet.name = splitLastSetName[0] + ' ' + (parseInt(splitLastSetName[1]) + 1).toString();
    }

    // we only want to add another set if
    // we don't have an empty set that needs stuff added to it
    if (show.sets.length === 0 || show.sets[show.sets.length -1].songs.length){
      show.sets.push(newSet);
      this.setState({show: show})
    }

  }
  handleRemoveSetClick(set){
    let show = this.state.show;
    // remove the last set if it is empty
    if (!show.sets[show.sets.length - 1].songs.length){
      show.sets.splice(show.sets.length - 1, 1);
      this.setState({show: show});
    }
  }
  renderSets(){
    let show = this.state.show;
    let songs = this.state.songs;
    if (!show || !show.sets){
      return [];
    }

    return show.sets.map((set, n) => {
      return (
        <EditSetModal onRemoveSetClick={this.handleRemoveSetClick} key={set.name} set={set} songs={songs}/>
      )
    })

  }

  render(){
    return(
    <Panel header={<HeaderAddButton label="Sets" onClick={this.handleAddClick} />}>
      {this.renderSets()}
    </Panel>
    )
  }
}

export default EditSets
import React from 'react';
import {Button, Label} from 'react-bootstrap';
import {Grid, Row, Col, Panel, Well, Table} from 'react-bootstrap';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

class ViewSongChild extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    this.state = {
      song: this.props.Song
    }
  }

  componentWillReceiveProps(nextProps){
    if (this.props.Song !== nextProps.Song){
      this.setState({song: nextProps.Song});
    }
  }

  renderCoverOrOriginalLabel(writtenBy){
    if (!writtenBy || !writtenBy.band) return '';

    let style = {verticalAlign: 'top', marginLeft: 5};
    if (writtenBy.band.toLowerCase() === 'spafford'){
      return <Label style={style} bsStyle="info">Spafford Original!</Label>
    } else {
      return <Label style={style} bsStyle="warning">Cover</Label>
    }
  }
  renderOneTimeJam(isJam){
    if (!isJam) return '';

    let style = {verticalAlign: 'top', marginLeft: 5};
    return <Label style={style} bsStyle="danger">Jam</Label>
  }

  renderList(list){
    if (!list || !list.length){
      return '';
    }

    let renderedList = list.map((item) => {
      return <ListGroupItem key={item}>{item}</ListGroupItem>
    });

    return (
      <ListGroup>
        {renderedList}
      </ListGroup>
    )

  }
  renderOtherNames(){
    let song = this.state.song;
    if (!song || !song.otherNames || !song.otherNames.length){
      return (<span>N/A</span>);
    }

    return song.otherNames.map((name) => {
      return <span key={abbr} style={{marginLeft: 2, marginRight: 2}}>{name},</span>
    })

  }

  renderWrittenBy(){
    let song = this.state.song;
    if (!song || !song.writtenBy){
      return ('')
    }

    let band = song.writtenBy.band;
    let authors = song.writtenBy.authors;
    // check if this is a cover
    if (band !== 'Spafford'){
      authors = [];
      authors.push(band);
    }
    return this.renderList(authors)
  }

  renderAlsoKnownAs(song){
    let alsoKnownAsList = [];
    if (song){
      if (song.abbreviations){
        song.abbreviations.forEach(abbr => alsoKnownAsList.push(abbr))
      }
      if (song.otherNames){
        song.otherNames.forEach(name => alsoKnownAsList.push(name))
      }
    }
    return this.renderList(alsoKnownAsList)
  }

  renderRow(header, value){
    if (!value || (Array.isArray(value) && !value.length)) {return ''}
    let col1 = 2;
    let col2 = 10;
    return (
      <Row>
        <Col md={col1} lg={col1} sm={col1} xs={col1}>
          <h4>{header}:</h4>
        </Col>
        <Col md={col2} lg={col2} sm={col2} xs={col2}>
          {value}
        </Col>
      </Row>
    )
  }

  renderInstruments(instruments){
    if (!instruments) return <div></div>;
    let output = (
      <Table striped condensed hover>
        <thead>
        <tr>
          <th>Instrument</th>
          <th>Player</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Lead Vocals</td>
          <td>{instruments.leadVocals}</td>
        </tr>
        <tr>
          <td>Backing Vocals</td>
          <td>{instruments.backingVocals && instruments.backingVocals.map((singer)=> <div>{singer}</div>)}</td>
        </tr>
        <tr>
          <td>Guitar</td>
          <td>{instruments.guitar}</td>
        </tr>
        <tr>
          <td>Piano</td>
          <td>{instruments.piano}</td>
        </tr>
        <tr>
          <td>Bass</td>
          <td>{instruments.bass}</td>
        </tr>
        <tr>
          <td>Drums</td>
          <td>{instruments.drums}</td>
        </tr>
        </tbody>
      </Table>
    );
    
    return output;
    
  }

  renderLyrics(lyrics){
    if (!lyrics) return '';
    console.log(lyrics);
    let arr = lyrics.split('\n');
    console.log(arr);
    return <Panel>{arr.map(line => <div>{line || <br />}</div>)}</Panel>
  }

  renderFirstPlayedLink(firstPlayed){
    let formattedDate = firstPlayed.date.substr(0,10);
    let link = <Link to={`/shows/${firstPlayed.showId}`}>{formattedDate}</Link>;
    let tooltip = <Tooltip id="tooltip">Show Info</Tooltip>;
    let overlay = <OverlayTrigger placement="right" overlay={tooltip}>
                    {link}
                  </OverlayTrigger>;
    return <Panel>{overlay}</Panel>;
  }

  render() {
    let song = this.state.song;
    let alsoKnownAsList = [];
    if (song){
      if (song.abbreviations){
        song.abbreviations.forEach(abbr => alsoKnownAsList.push(abbr))
      }
      if (song.otherNames){
        song.otherNames.forEach(name => alsoKnownAsList.push(name))
      }
    }
    return (
      <Grid className="marginBottom">
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            <h1 style={{display: 'inline'}}>{song.name}</h1><h4 style={{display: 'inline'}}>{this.renderCoverOrOriginalLabel(song.writtenBy)}{this.renderOneTimeJam(song.isJam)}</h4>
            <hr></hr>
          </Col>
        </Row>
        {this.renderRow('Written By', this.renderWrittenBy())}
        {this.renderRow('Also Known As', this.renderAlsoKnownAs(song))}
        {this.renderRow('Performed By', this.renderInstruments(song.instruments))}
        {this.renderRow('First Time Played', song && song.firstPlayed && this.renderFirstPlayedLink(song.firstPlayed))}
        {this.renderRow('Lyrics', this.renderLyrics(song.lyrics))}
        <Row>
          <Col>
            {this.props.loggedIn && <div className="pull-right">
              <LinkContainer className="editBtnCSS" to={`/songs/edit/${song._id}`}>
                <Button bsStyle="primary">Edit</Button>
              </LinkContainer>
            </div>
            }
          </Col>
        </Row>
      </Grid>
    );

  }
}

export default ViewSongChild;

import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col, Well, Panel} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl, Checkbox} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';

import EditInstruments from './songedit/EditInstruments.jsx'
import EditFirstTimePlayed from './songedit/EditFirstTimePlayed.jsx'
import ListGroupEditor from '../shared/ListGroupEditor.jsx';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.history = props.history;

    this.state = {
      song: this.props.Song
    };

    this.handleSongIDChange = this.handleSongIDChange.bind(this);
    this.handleSongNameChange = this.handleSongNameChange.bind(this);
    this.handleBandChange = this.handleBandChange.bind(this);
    this.handleFirstShowChange = this.handleFirstShowChange.bind(this);
    this.handleAbbreviationsChange = this.handleAbbreviationsChange.bind(this);
    this.handleOtherNameChange = this.handleOtherNameChange.bind(this);
    this.handleInstrumentsChanged = this.handleInstrumentsChanged.bind(this);
    this.handleAuthorsChange = this.handleAuthorsChange.bind(this);
    this.handleLyricsChange = this.handleLyricsChange.bind(this);
    this.handleIsJamChange = this.handleIsJamChange.bind(this);


    this._formSubmit = this._formSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    let thisSongID = this.state && this.state.song && this.state.song._id;
    let nextSongID = nextProps.Song && nextProps.Song._id;
    if (thisSongID !== nextSongID){
      this.setState({song: nextProps.Song})
    }
  }

  handleSongIDChange(e){
    let song = this.state.song;
    song._id = e.target.value;
    this.setState({song: song});
  }
  handleSongNameChange(e){
    let song = this.state.song;
    song.name = e.target.value;
    // TODO: we also want to update the id of the song if we are creating
    // song._id = song.name.toLowerCase().replace(/ /g,'-');
    this.setState({song: song});
  }
  handleIsJamChange(e){
    let song = this.state.song;
    song.isJam = !song.isJam;
    this.setState({song: song})
  }
  handleBandChange(e){
    let song = this.state.song;
    if (!song.writtenBy) {song.writtenBy = {}}
    song.writtenBy.band = e.target.value;
    this.setState({song: song});
  }
  handleFirstShowChange(show){
    let song = this.state.song;
    song.firstPlayed = {};
    song.firstPlayed.showId = show._id;
    song.firstPlayed.date = show.date;
    song.firstPlayed.venue = show.venue;
    this.setState({song: song});
  }
  handleAuthorsChange(value){
    let song = this.state.song;
    if (!song.writtenBy) {song.writtenBy = {}}
    song.writtenBy.authors = value;
    this.setState({song: song});
  }

  handleAbbreviationsChange(value){
    let song = this.state.song;
    song.abbreviations = value;
    this.setState({song: song});
  }

  handleOtherNameChange(value){
    let song = this.state.song;
    song.otherNames = value;
    this.setState({song: song});
  }

  handleInstrumentsChanged(instruments){
    let song = this.state.song;
    song.instruments = instruments;
    this.setState({song: song});
  }

  handleLyricsChange(e){
    let song = this.state.song;
    song.lyrics = e.target.value;
    this.setState({song: song});
  }

  _formSubmit(e) {
    e.preventDefault();
    this.props.formSubmit(this.state.song);
  }

  render() {
    let song = this.state.song;
    //if (!song._id) { return <div></div> }
    let showId = song.firstPlayed && song.firstPlayed.showId || '';
    let band = song.writtenBy && song.writtenBy.band || '';
    return (
        <Grid >
          <Row>
            <Col md={12}>
              <FormGroup controlId="id">
                <ControlLabel>ID</ControlLabel>
                <FormControl
                  id="id"
                  type="text"
                  value={song._id}
                  onChange={this.handleSongIDChange}/>
              </FormGroup>
              <FormGroup controlId="name">
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  id="name"
                  type="text"
                  value={song.name}
                  onChange={this.handleSongNameChange}/>
              </FormGroup>
              <FormGroup controlId="isJam">
                <Checkbox checked={song.isJam} onChange={this.handleIsJamChange}>
                  One Time Jam
                </Checkbox>
              </FormGroup>
              <FormGroup controlId="band">
                <ControlLabel>Band:</ControlLabel>
                <FormControl
                  id="band"
                  type="text"
                  value={band}
                  onChange={this.handleBandChange}/>
              </FormGroup>
              <FormGroup controlId="firstTimePlayed">
                <ControlLabel>First Time Played:</ControlLabel>
                <EditFirstTimePlayed selectedShowId={showId} onChange={this.handleFirstShowChange}/>
              </FormGroup>

              <ListGroupEditor header="Written By" onChange={this.handleAuthorsChange} data={song.writtenBy && song.writtenBy.authors} isEdit={true} />

              <ListGroupEditor header="Other Names" onChange={this.handleOtherNameChange} data={song.otherNames} isEdit={true} />

              <ListGroupEditor header="Abbreviations" onChange={this.handleAbbreviationsChange} data={song.abbreviations} isEdit={true} />

              <EditInstruments instruments={song.instruments} onChange={this.handleInstrumentsChanged} />

              <Panel header={<h4>Lyrics:</h4>}>
                <FormGroup controlId="lyrics">
                  <FormControl style={{height: 600}}
                    componentClass="textarea"
                    placeholder="Lyrics"
                    value={song.lyrics}
                    onChange={this.handleLyricsChange}
                  />
                </FormGroup>
              </Panel>

              <Button bsStyle="primary" className="pull-right" onClick={this._formSubmit}>Submit Button</Button>
            </Col>
          </Row>
        </Grid>
      );
  }
  }

export default Form;

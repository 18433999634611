import React from 'react';
import { Link } from 'react-router';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import {LinkContainer, IndexLinkContainer} from 'react-router-bootstrap';
import auth from './../../services/Authentication';
import UserStore from './../../stores/UserStore.jsx';
import LoggedInNav from './navbars/LoggedInNav.jsx';
import LoggedOutNav from './navbars/LoggedOutNav.jsx';


class Header extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.state.loggedIn = auth.loggedIn();
    this.state.userID = '';
    this.state.username = '';
    this.state.userID = auth.getUserId();
    this.state.username = auth.getUserName();
    this.navClick = this.navClick.bind(this);
    this.collapse = this.collapse.bind(this);
    this.updateAuth = this.updateAuth.bind(this);
    this.router = context.router;
  }

  navClick() {
    this.setState({ expand:false });
  }

  collapse(expanded) {
    this.setState({ expand: expanded });
  }

  updateAuth(loggedIn, path) {
    this.setState({ loggedIn: loggedIn });
    this.setState({userID: auth.getUserId()});
    this.setState({username: auth.getUserName()});
    console.log(loggedIn);
    console.log(path);
    if (loggedIn && path == 'signin') {
      this.router.push('/');
    }
  }

  componentWillMount() {
    auth.onChange = this.updateAuth;
  }

  render() {
    return (
      <Navbar
        inverse
        className="customNav"
        expanded={this.state.expand}
        onToggle={this.collapse}>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/"><img className="brand_logo"/>Spaffnerds Admin</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {this.state.loggedIn ? (
              <LoggedInNav username={this.state.username} userID={this.state.userID} />
            ) : (
              <LoggedOutNav/>
            )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Header.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Header;

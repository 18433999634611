import React from 'react';
import {Router, Route, IndexRoute, browserHistory} from 'react-router';
import Template from './core/Template.jsx';
import Home from './core/Home.jsx';

import CreateArticle from './articles/CreateArticle.jsx';
import ViewArticle from './articles/ViewArticle.jsx';
import ListArticles from './articles/ListArticles.jsx';
import EditArticle from './articles/EditArticle.jsx';

import CreateSong from './songs/CreateSong.jsx';
import ViewSong from './songs/ViewSong.jsx';
import ListSongs from './songs/ListSongs.jsx';
import EditSong from './songs/EditSong.jsx';

import CreateShow from './shows/CreateShow.jsx';
import ViewShow from './shows/ViewShow.jsx';
import ListShows from './shows/ListShows.jsx';
import EditShow from './shows/EditShow.jsx';

import auth from './../services/Authentication';
import Signup from './users/Signup.jsx';
import Signin from './users/Signin.jsx';
import SignOut from './users/SignOut.jsx';
import EditUserProfile from './users/EditUserProfile.jsx';

function requireAuth(nextState, replace) {
  if (!auth.loggedIn())
    replace({ nextPathname: nextState.location.pathname }, '/signin');
}

export default(props) => {
  return (

  <Router history={browserHistory} >
    <Route path="/signin" component={Signin}/>
    <Route path="/" component={Template}>
        <IndexRoute component={Home} onEnter={requireAuth}/>

        <Route path="/songs" component={ListSongs} onEnter={requireAuth}/>
        <Route path="/songs/create" component={CreateSong} onEnter={requireAuth}/>
        <Route path="/songs/:id" component={ViewSong} onEnter={requireAuth}/>
        <Route path="/songs/edit/:id" component={EditSong} onEnter={requireAuth}/>

        <Route path="/shows" component={ListShows} onEnter={requireAuth}/>
        <Route path="/shows/create" component={CreateShow} onEnter={requireAuth}/>
        <Route path="/shows/:id" component={ViewShow}  onEnter={requireAuth}/>
        <Route path="/shows/edit/:id" component={EditShow} onEnter={requireAuth}/>


        <Route path="/signout" component={SignOut}/>
        <Route path="/users/edit/:id" component={EditUserProfile} onEnter={requireAuth}/>
      </Route>
    </Router>
  );
};

import React from 'react';
import SongStore from './../../stores/SongStore.jsx';
import {Grid, Row, Col} from 'react-bootstrap';
import Form from './Form.jsx';

class CreateSong extends React.Component {
 constructor(props) {
   super(props);
   this.history = props.history;
   this.state = {};
   this.state.Song = {};
   this.state.error = '';
   this.handleSongUpdate=this.handleSongUpdate.bind(this);

   this._formSubmit = this._formSubmit.bind(this);
 }

  handleSongUpdate(value) {
    this.setState({ Song: value });
  };

_formSubmit(value) {
   SongStore.addSong(value, this.history);
 }

 render() {

   return (
     <Grid className="marginBottom">
        <Row>
          <Col md={12}>
            <h2>
              <b>Create Song</b>
            </h2>
            <hr></hr>
            <Form formSubmit={this._formSubmit} Song={this.state.Song}
                />
          </Col>
        </Row>
      </Grid>
   )
 }
}

export default CreateSong;

import React from 'react';
import {Grid, Row, Col, Button, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
class HeaderAddButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    this.state = {
      keyVal: this.props.keyValue,
      valVal: this.props.valueValue
    };
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleAddClick(e){
    e.preventDefault();
    if (this.props.onClick){
      this.props.onClick(e)
    }
  }

  render(){
    let label = this.props.label;
    return <h4>{label}<Button bsStyle="success" bsSize="xsmall" style={{marginLeft: 10}} onClick={this.handleAddClick}><i className="fa fa-plus-circle"/></Button></h4>
  }

}

export default HeaderAddButton;
import React from 'react';
import SongStore from './../../stores/SongStore.jsx';
import ListSongsChild from './ListSongsChild.jsx';

function getSongList() {
  return { Songs: SongStore.getSongListSorted('name') };
}

class SongList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    SongStore.fetchSongList();
    this.state = {};
    this.state.Songs = [];
    this._onChange = this._onChange.bind(this);
  }

  componentWillMount() {
    SongStore.onChange(this._onChange);
  }

  componentWillUnmount() {
    SongStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getSongList());
  }

  render() {
    return (
      <ListSongsChild Songs={this.state.Songs} filter={this.props.location.query.filter}/>
    );
  }
}
export default SongList;

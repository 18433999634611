import React from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
class Footer extends React.Component {
    constructor() {
      super();

    }

	render() {
  return (
            <div className="footer">
            </div>
		);
	}

}

export default Footer;

import moment from 'moment';
import React from 'react';
import {Grid, Row, Col} from 'react-bootstrap';

class ShowHeader extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    let show = this.props.show;
    let formattedDate = show.date && show.date.substr(0,10);
    if (show.venue){
      var venue = show.venue.name || "";
      var city = show.venue.city || "";
      var state = show.venue.state || "";
    }
    return (
      <Grid fluid>
        <Row>
          <Col lg={3}>{formattedDate}</Col>
          <Col lg={6}>{venue}</Col>
          <Col lg={3}>{city}, {state}</Col>
        </Row>
      </Grid>
    )
  }
}

export default ShowHeader;
import React from 'react';
import {Button, Label} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Well, Panel, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {ListGroup, ListGroupItem} from 'react-bootstrap';

import ShowStore from './../../../stores/ShowStore.jsx';

function getShowList() {
  return { shows: ShowStore.getShowListSorted('date') };
}
class FirstTimePlayed extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      shows: [],
      selectedShowId: this.props.selectedShowId
    };
    
    this.handleShowChange = this.handleShowChange.bind(this);
    
    ShowStore.fetchShowList();
    this._onChange = this._onChange.bind(this);

  }
  componentWillReceiveProps(nextProps){
    if(this.props.selectedShowId !== nextProps.selectedShowId){
      this.setState({selectedShowId: nextProps.selectedShowId})
    }
  }
  componentWillMount() {
    ShowStore.onChange(this._onChange);
  }

  componentWillUnmount() {
    ShowStore.removeChangeListener(this._onChange);
  }

  handleShowChange(e){
    if (this.props.onChange){
      let shows = this.state.shows;
      let id = e.target.value;
      let show = shows.find((show) => {return show._id === id});
      if (show){
        this.props.onChange(show);
      }
    }
  }
  _onChange() {
    this.setState(getShowList());
  }

  render(){
    let shows = this.state.shows;
    let selectedShowId = this.state.selectedShowId;
    return (
      <FormControl componentClass="select"
                   placeholder="Choose Show"
                   value={selectedShowId}
                   onChange={this.handleShowChange}
      >
        <option value="">--Choose Show--</option>
        {shows.map((show) => {
          let formattedDate = show.date.substr(0,10);
          return <option key={show._id} value={show._id}>{formattedDate}</option>
        })}
      </FormControl>
    )
  }
}

export default FirstTimePlayed;
import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Panel, Well, Table} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import HeaderAddButton from '../../shared/HeaderAddButton.jsx';

/// Props: 
///   data: []
//      The records to display
//    header: String
//      The header to display
//    onChange: function(changedData)
//      handles changes to the data
class EditGuestMusicians extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);

    this.handleEditExistingRecord = this.handleEditExistingRecord.bind(this);
    this.handleEditRecordFieldChange = this.handleEditRecordFieldChange.bind(this);
    this.handleEditConfirm = this.handleEditConfirm.bind(this);
    this.handleEditDelete = this.handleEditDelete.bind(this);

    this.state = {
      data: (this.props.data && this.props.data.slice(0)) || [],
      editIndex: -1,
      editRecord: null
    }
  }

  newGuest(){
    return {
      firstName: '',
      lastName: '',
      band: '',
      instrument: ''
    }
  }

  componentWillReceiveProps(nextProps){
    if ( JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data) ) {
      let data = (nextProps.data && nextProps.data.slice(0)) || [];
      this.setState({data: data});
    }
  }

  handleAddClick() {
    let data = this.state.data || [];
    let newGuest = this.newGuest();
    data.push(newGuest);
    this.setState({
      data: data,
      editIndex: data.length -1
    });
  }
  handleEditExistingRecord(n){
    let data = this.state.data;
    this.setState({
      editIndex: n,
      editRecord: data[n]
    });
  }

  handleEditRecordFieldChange(field, value){
    let data = this.state.data;
    let editIndex = this.state.editIndex;
    let editRecord = data[editIndex];
    editRecord[field] = value;
    data[editIndex] = editRecord;
    this.setState({data: data});
  }

  handleEditConfirm(e){
    let data = this.state.data;
    let editIndex = this.state.editIndex;
    let editRecord = data[editIndex];
    // if there's data, then confirm it
    if ( editRecord.firstName || editRecord.lastName || editRecord.band || editRecord.instrument ){
      // clear edit mode
      this.setState({editIndex: -1});
      // notify that we're accepting the change
      if (this.props.onChange){
        this.props.onChange(data);
      }
    } else {
      // there's no data in the record so delete it
      this.handleEditDelete(editIndex);
    }
  }

  handleEditDelete(n){
    let data = this.state.data;
    data.splice(n,1);
    this.setState({
      data: data,
      editRecord: null
    });
    if (this.props.onChange){
      this.props.onChange(data);
    }
  }

  renderRecords(){
    let data = this.state.data;
    let editIndex = this.state.editIndex;
    let rendered = [];
    for (let i = 0; i < data.length; i++){
      let record = data[i];
      if (i === editIndex){
        rendered.push(
          <tr key={i}>
            <td></td>
            <td>
              <FormControl
                type="text"
                value={record.firstName}
                onChange={(e) => this.handleEditRecordFieldChange('firstName', e.target.value)}
              />
            </td>
            <td>
              <FormControl
                type="text"
                value={record.lastName}
                onChange={(e) => this.handleEditRecordFieldChange('lastName', e.target.value)}
              />
            </td>
            <td>
              <FormControl
                type="text"
                value={record.band}
                onChange={(e) => this.handleEditRecordFieldChange('band', e.target.value)}
              />
            </td>
            <td>
              <FormControl
                type="text"
                value={record.instrument}
                onChange={(e) => this.handleEditRecordFieldChange('instrument', e.target.value)}
              />
            </td>
            <td><Button bsSize="xsmall" bsStyle="success" onClick={this.handleEditConfirm}><i className="fa fa-check-circle"/></Button></td>
          </tr>
        )
      } else {
        rendered.push(
          <tr key={record.lastName + "_" + i}>
            <td><Button bsSize="xsmall" bsStyle="warning" onClick={this.handleEditExistingRecord.bind(null,i)}><i className="fa fa-pencil-square-o"/></Button></td>
            <td>{record.firstName}</td>
            <td>{record.lastName}</td>
            <td>{record.band}</td>
            <td>{record.instrument}</td>
            <td><Button bsSize="xsmall" bsStyle="danger" onClick={this.handleEditDelete.bind(null,i)}><i className="fa fa-trash"/></Button></td>
          </tr>
        )
      }
    }

    return rendered;
  }
  render(){
    let headerText = this.props.header;
    let header = <HeaderAddButton label={headerText} onClick={this.handleAddClick} />;
    let renderedRecords = this.renderRecords();
    return (
      <Panel header={header}>
        {renderedRecords.length
          ? <Table striped condensed hover>
            <thead>
            <tr>
              <th></th>
              <th>First</th>
              <th>Last</th>
              <th>Band</th>
              <th>Instrument</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
             {renderedRecords}
             </tbody>
            </Table>
          : ''
        }
      </Panel>
    )
  }
}

export default EditGuestMusicians;



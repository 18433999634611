import React from 'react';
import ShowStore from './../../stores/ShowStore.jsx';
import ListShowsChild from './ListShowsChild.jsx';

function getShowList() {
  return { Shows: ShowStore.getShowListSorted('date') };
}

class ShowList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    ShowStore.fetchShowList();
    this.state = {};
    this.state.Shows = [];
    this._onChange = this._onChange.bind(this);
  }

  componentWillMount() {
    ShowStore.onChange(this._onChange);
  }

  componentWillUnmount() {
    ShowStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getShowList());
  }

  render() {
    return (
      <ListShowsChild Shows={this.state.Shows} />
    );
  }
}
export default ShowList;

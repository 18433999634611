import React from 'react';
import {Grid, Row, Col, Button, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import DatePicker from 'react-bootstrap-date-picker';
import EditStartEndTimes from './showedit/EditStartEndTimes.jsx'
import EditTickets from './showedit/EditTickets.jsx'
import EditVenue from './showedit/EditVenue.jsx'
import EditSets from './showedit/EditSets.jsx'
import EditShowNotes from './showedit/EditShowNotes.jsx'
import ListEditor from '../shared/KeyValueListEditor.jsx';
import SongStore from './../../stores/SongStore.jsx';

function getSongList() {
  return { Songs: SongStore.getSongListSorted('name') };
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Songs: [],
      show: this.props.Show
    };
    SongStore.fetchSongList();
    this.history = props.history;
    // this.handleInput_id = this.handleInput_id.bind(this);

    this.handleInputDate = this.handleInputDate.bind(this);
    this.handleStartEndTimeChange = this.handleStartEndTimeChange.bind(this);
    this.handleTicketsChange = this.handleTicketsChange.bind(this);
    this.handleVenueChange = this.handleVenueChange.bind(this);
    this.handleShowNotesChange = this.handleShowNotesChange.bind(this);

    this._formSubmit = this._formSubmit.bind(this);
    this._onSongChange = this._onSongChange.bind(this);
  }
  componentWillMount() {
    SongStore.onChange(this._onSongChange);
  }
  componentWillUnmount() {
    SongStore.removeChangeListener(this._onSongChange);
  }
  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.Show) !== JSON.stringify(nextProps.Show)){
      this.setState({show: nextProps.Show});
    }
  }

  _onSongChange() {
    this.setState(getSongList());
  };

  // handleInput_id(e) {
  //  this.props.Show._id = e.target.value;
  //  this.props.handleShowUpdate(this.props.Show);
  // }

  handleInputDate(value, formattedValue) {
    let show = this.state.show;
    show.date = value.substr(0,10) + "T00:00:00.000Z";
    this.setState({show: show});
  }
  handleStartEndTimeChange(changedShow){
    let show = this.state.show;
    show.startTime = changedShow.startTime;
    show.endTime = changedShow.endTime;
    show.multiShowIndex = changedShow.multiShowIndex || 0;
    show.date = changedShow.date;
    this.setState({show: show});
  }
  handleTicketsChange(tickets){
    let show = this.state.show;
    show.tickets = tickets;
    this.setState({show: show});
  }
  handleVenueChange(venue){
    let show = this.state.show;
    show.venue = venue;
    this.setState({show: show});
  }
  handleShowNotesChange(showNotes){
    let show = this.state.show;
    show.notes = showNotes;
    this.setState({show: show});
  }

  _formSubmit(e) {
    e.preventDefault();
    let show = this.state.show;
    this.props.formSubmit(show);
  }

  render() {
    let Show = this.state.show;
    let Songs = this.state.Songs;
    return (
        <Grid>
          <Row>
            <Col md={12}>
              <FormGroup controlId="showDate">
                <ControlLabel>Show Date</ControlLabel>
                <DatePicker
                  id="showDate"
                  value={Show.date}
                  onChange={this.handleInputDate}/>
              </FormGroup>

              <EditStartEndTimes show={Show} onChange={this.handleStartEndTimeChange}/>

              <EditTickets tickets={Show.tickets} onChange={this.handleTicketsChange}/>

              <EditVenue venue={Show.venue} onChange={this.handleVenueChange}/>

              <EditShowNotes notes={Show.notes} onChange={this.handleShowNotesChange} />

              <EditSets show={Show} songs={Songs}/>

              <Button bsStyle="primary" className="pull-right" onClick={this._formSubmit}>Submit Button</Button>
            </Col>
          </Row>
        </Grid>
      );
  }
}

export default Form;

import moment from 'moment';
import React from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import SongPlay from './SongPlay.jsx'

class Set extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render(){
    let set = this.props.set;
    let setDisplayName = this.props.setDisplayName || set.name;
    let songDisplay = '';
    if (set && set.songs){
      songDisplay = set.songs.map((song, n, songs) => {
        return <SongPlay key={song._id + n} song={song} last={songs.length -1 === n}/>
      });
    }


    return (
      <div>
        <h3>{setDisplayName}</h3>
        <div>
          {songDisplay}
        </div>
      </div>
    )
  }
}


export default Set;
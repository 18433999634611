import React from 'react';
import {
  Grid,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import ShowHeader from './showview/ShowHeader.jsx';
import Set from './showview/Set.jsx';

import UserAttendanceButton from './showview/UserAttendanceButton.jsx';
import UserStore from './../../stores/UserStore.jsx';
import auth from './../../services/Authentication';

const API_URL = "https://api.spaffnerds.com/v1";
// const API_URL = "http://dev.spaffnerds.com:4000/v1";

class ViewShowChild extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    if (auth.loggedIn()){
      UserStore.fetchUser(auth.getUserId());
    }
    this.state = {user: UserStore.getUser()};
    this.deleteShow = this.deleteShow.bind(this);
    this._onChange = this._onChange.bind(this);
  }
  componentWillMount() {
    UserStore.onChange(this._onChange);
  }
  componentWillUnmount() {
    UserStore.removeChangeListener(this._onChange);
  }
  _onChange() {
    this.setState({user: UserStore.getUser()});
  }

  deleteShow() {
    this.props.deleteShow(this.props.ShowId);
  }

  render() {
    let user = this.state.user;
    let Show = this.props.Show;
    let setDisplay = "";
    if (Show.sets){
      // figure out if there's exactly 1 encore
      let numOfEncores = Show.sets.filter((set) => set.name.substr(0,6).toLowerCase() === 'encore').length;
      setDisplay = Show.sets.map((set) => {
        if (numOfEncores === 1 && set.name.substr(0,6).toLowerCase() === 'encore'){
          return <Set key={set.name} set={set} setDisplayName='Encore'/>
        }
        return <Set key={set.name} set={set} />
      });
    }
    return (
      <Grid className="marginBottom">
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            <h1><ShowHeader show={Show}/>
            </h1>
            <hr />
            {this.props.loggedIn &&
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div className="text-center">
                    <a className="editBtnCSS" href={`${API_URL}/stats/user/invalidate/by-showid/${Show._id}`} target="_blank">
                      <Button bsStyle="warning"><i className="fa fa-group" style={{paddingRight: 5}}/>Invalidate User Stats</Button>
                    </a>
                  </div>
                </Col>
                <Col md={6} lg={6} sm={12} xs={12}>
                  <div className="text-center">
                    <a className="editBtnCSS" href={`${API_URL}/stats/all/create`} target="_blank">
                      <Button bsStyle="danger"><i className="fa fa-music" style={{paddingRight: 5}}/>Invalidate All Song Stats</Button>
                    </a>
                  </div>
                </Col>
              </Row>
            }
          </Col>
        </Row>
        <Row>
          {setDisplay}
        </Row>
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            {this.props.loggedIn && <div className="pull-right">
              <LinkContainer className="editBtnCSS" to={`/shows/edit/${Show._id}`}>
                <Button bsStyle="primary">Edit</Button>
              </LinkContainer>
            </div>
            }
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ViewShowChild;

import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col, Well} from 'react-bootstrap';
import {FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import SongPlay from '../showview/SongPlay.jsx'
import ListGroupEditor from '../../shared/ListGroupEditor.jsx'
import EditGuestMusicians from './EditGuestMusicians.jsx'

class EditSongList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editSong: null,
      song: {}
    };

    this.handleAddSongClick = this.handleAddSongClick.bind(this);

    this.handleSongClick = this.handleSongClick.bind(this);
    this.handleSongChange = this.handleSongChange.bind(this);
    this.handleJamOutChange = this.handleJamOutChange.bind(this);
    this.handleSongNotesChange = this.handleSongNotesChange.bind(this);
    this.handleGuestsChange = this.handleGuestsChange.bind(this);

  }
  handleAddSongClick(e){
    let songList = this.props.songs;
    let newSong = {
      _id: '',
      name: '',
      jamOut: 0
    };
    if (songList.length === 0 || (songList[songList.length - 1] && songList[songList.length - 1]._id)){
      songList.push(newSong);
      this.setState({
        editSong: songList.length -1,
        song: newSong
      });
    }
  }
  handleSongClick(n){
    let oldSongIndex = this.state.editSong;
    let songList = this.props.songs;
    // if there was an old song and it isn't valid
    if (oldSongIndex !== null && ! songList[oldSongIndex]._id && n !== oldSongIndex) {
      //then remove the old song
      songList.splice(oldSongIndex, 1);
    }

    let newState = {editSong: n};
    if (n !== null){
      newState.song = this.props.songs[n];
    }
    this.setState(newState);
  }

  handleSongChange(e){
    let songPlay = this.props.songs[this.state.editSong];
    let newSong = this.props.allSongs.filter((song) => {return song._id === e.target.value})[0];
    if (songPlay){
      if (!newSong){
        songPlay._id = '';
        songPlay.name = '';
        songPlay.jamOut = 0;
      } else {
        songPlay._id = newSong._id;
        songPlay.name = newSong.name;
      }
    }
    this.setState({song: songPlay})
  }
  handleJamOutChange(e){
    let songPlay = this.props.songs[this.state.editSong];
    let newJamOut = e.target.value;
    if (songPlay){
      songPlay.jamOut = parseInt(newJamOut);
    }
    this.setState({song: songPlay})
  }

  handleSongNotesChange(notes){
    let song = this.state.song;
    if (song) {
      song.notes = notes;
      this.setState({song: song});
    }
  }
  handleGuestsChange(guests){
    let song = this.state.song;
    if (song) {
      song.guests = guests;
      this.setState({song: song});
    }
  }

  renderButton(n){
    let songs = this.props.songs;
    let style = {marginLeft: 2, marginRight: 2};
    if (n === songs.length - 1){
      return <Button bsSize="xs" bsStyle="success" style={style}><i className="fa fa-plus-circle"/></Button>
    } else if (songs[n].jamOut){
      return <Button bsSize="xs" bsStyle="info" style={style}><i className="fa fa-chevron-circle-right"/></Button>
    } else {
      return <Button bsSize="xs" bsStyle="info" style={style}><i className="fa fa-circle"/></Button>
    }
  }

  renderAddButton(){
    let style = {marginLeft: 2, marginRight: 2};
    return <Button bsSize="xs" bsStyle="success" style={style} onClick={this.handleAddSongClick}><i className="fa fa-plus-circle"/></Button>
  }

  renderSongDisplay(n){
    let songs = this.props.songs;
    let song = songs[n];
    let style = {marginLeft: 2, marginRight: 2};
    let bsStyle = this.state.editSong === n ? "btn btn-warning" : null;

    if (song._id === ''){
      return <Button bsSize="xs" style={style} className={bsStyle} onClick={this.handleSongClick.bind(null, n)}>
                <span>-- Choose Song --</span>
              </Button>
    }
    return <Button bsSize="xs" style={style} className={bsStyle} onClick={this.handleSongClick.bind(null, n)}>
            <SongPlay key={song._id + n} song={song} last={songs.length - 1 === n} nolink/>
          </Button>
  }

  renderSongEdit(){
    let editSong = this.state.editSong;
    let song = this.state.song;
    let allSongs = this.props.allSongs;
    let songNotes = song.notes;
    let guests = song.guests;

    if (editSong !== null && editSong >= 0){
      return(
      <Well>
        <Grid fluid>
          <Row>
            <Col>
              <FormGroup>
                <ControlLabel>Song Name</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={song._id} onChange={this.handleSongChange}>
                  <option key={-1} value="">-- Choose Song --</option>
                  {allSongs.map((song, n)=>{
                    return <option key={song._id + n} value={song._id}>{song.name}</option>
                  })}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="_id">
                <ControlLabel>Jam Out</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={song.jamOut}  onChange={this.handleJamOutChange}>
                  <option value={0}>none</option>
                  <option value={1}>&gt;</option>
                  <option value={2}>&gt;&gt;</option>
                  <option value={3}>&gt;&gt;&gt;</option>
                </FormControl>
              </FormGroup>

              <ListGroupEditor header="Notes" data={songNotes} onChange={this.handleSongNotesChange} />

              <EditGuestMusicians header="Guests" data={guests} onChange={this.handleGuestsChange} />

              <Button bsStyle="success" onClick={this.handleSongClick.bind(null, null)} className="pull-right">Ok</Button>
            </Col>
          </Row>
        </Grid>
      </Well>
      )
    }

  }

  render() {
    let songs = this.props.songs;
    let editSong = this.state.editSong;
    let songDisplay = [];
    for( let n = 0; n < songs.length; n++){
      songDisplay.push(this.renderSongDisplay(n));
    }

    return (<Grid fluid>
              <Row>
                <Col>
                  {songDisplay}
                  {this.renderAddButton()}
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.renderSongEdit()}
                </Col>
              </Row>
            </Grid>
            )
  }
}

export default EditSongList;


import React from 'react';
import ShowStore from './../../stores/ShowStore.jsx';
import {Grid, Row, Col} from 'react-bootstrap';
import Form from './Form.jsx';

class CreateShow extends React.Component {
 constructor(props) {
   super(props);
   this.history = props.history;
   this.state = {};
   this.state.Show = {};
   this.state.error = '';
   this.handleShowUpdate=this.handleShowUpdate.bind(this);
   this._formSubmit = this._formSubmit.bind(this);
 }

handleShowUpdate(value) {
 this.setState({ Show: value }); 
};

_formSubmit(value) {
   ShowStore.addShow(value, this.history);
 }

 render() {

   return (
     <Grid className="marginBottom">
        <Row>
          <Col md={12}>
            <h2>
              <b>Create Show</b>
            </h2>
            <hr></hr>
            <Form
              formSubmit={this._formSubmit}
              Show={this.state.Show}
              handleShowUpdate={this.handleShowUpdate} />
          </Col>
        </Row>
      </Grid>
   )
 }
}

export default CreateShow;

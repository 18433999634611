import React from 'react';
import SongStore from './../../stores/SongStore.jsx';
import {Grid, Row, Col} from 'react-bootstrap';
import Form from './Form.jsx';

function getSong() {
  return { EditSong: SongStore.getSong() };
}

class EditSong extends React.Component {
    constructor(props) {
    super(props);
    this.state = {};
    this.history = props.history;
    SongStore.fetchSong(props.params.id);
    this.state = getSong();
    
    this._formSubmit = this._formSubmit.bind(this);
    this._onSongChange = this._onSongChange.bind(this);

  }
  componentWillMount() {
    SongStore.onChange(this._onSongChange);
  }

  componentWillUnmount() {
    SongStore.removeChangeListener(this._onSongChange);
  }
  _onSongChange(){
    this.setState(getSong());
  }
  _formSubmit(value) {
    SongStore.editSong(value, value._id, this.history);
  }

  render() {
    return (
      <Grid className="marginBottom">
        <Row>
          <Col md={12}>
            <h2>
              <b>Edit Song</b>
            </h2>
            <hr></hr>
            <Form formSubmit={this._formSubmit}  Song={this.state.EditSong}
               />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default EditSong;

import React from 'react';
import ShowStore from './../../stores/ShowStore.jsx';
import SongStore from './../../stores/SongStore.jsx';
import {Grid, Row, Col} from 'react-bootstrap';
import Form from './Form.jsx';

function getShow() {
  return { EditShow: ShowStore.getShow() };
}

class EditShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.history = props.history;
    ShowStore.fetchShow(props.params.id);

    this.state = {EditShow: {}};
    let currentShow = getShow();
    if (currentShow.EditShow._id === props.params.id){
      this.state = currentShow;
    }

    this.handleInput_id= this.handleInput_id.bind(this);
    this.handleInputDate= this.handleInputDate.bind(this);
    this.handleShowUpdate = this.handleShowUpdate.bind(this);
    this._formSubmit = this._formSubmit.bind(this);
    this._onShowChange = this._onShowChange.bind(this);
  }

  componentWillMount() {
    ShowStore.onChange(this._onShowChange);
  }

  componentWillUnmount() {
    ShowStore.removeChangeListener(this._onShowChange);
  }


  handleInput_id(value) { 
    this.setState({ Show: value });
  };
 
  handleInputDate(value) {
    this.setState({ Show: value });
  };

  handleShowUpdate(value) {
    this.setState({ Show: value });
  };

  _formSubmit(value) {
    ShowStore.editShow(value, value._id, this.history);
  };

  _onShowChange() {
    this.setState(getShow());
  };

  render() {
    return (
      <Grid className="marginBottom">
        <Row>
          <Col md={12}>
            <h2>
              <b>Edit Show</b>
            </h2>
            <hr />
            <Form
              formSubmit={this._formSubmit}
              Show={this.state.EditShow}
              handleShowUpdate={this.handleShowUpdate} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default EditShow;

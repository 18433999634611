import moment from 'moment';
import React from 'react';
import {Link} from 'react-router';
import {
  Grid,
  Row,
  Col,
  Panel,
  Pagination,
  Button,
  Well,
  Label,
  Table,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import UserAttendanceButton from './showview/UserAttendanceButton.jsx';

import UserStore from './../../stores/UserStore.jsx';
import auth from './../../services/Authentication';

class ListShowsChild extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    if (auth.loggedIn()){
      UserStore.fetchUser(auth.getUserId());
    }
    this.state = {
      user: UserStore.getUser()
    };

    this._onChange = this._onChange.bind(this);
  }
  componentWillMount() {
    UserStore.onChange(this._onChange);
  }
  componentWillUnmount() {
    UserStore.removeChangeListener(this._onChange);
  }
  _onChange() {
    this.setState({user: UserStore.getUser()});
  }

  render() {

    let user = this.state.user;
    let showCount = 0;
    if (user && user.shows && user.shows.length){
      showCount = user.shows.length;
    }
    let renderNotes = function(notes) {
      var tags = [];
      for (var property in notes){
        if (notes.hasOwnProperty(property)){
          let tooltip = <Tooltip id="tooltip">{notes[property]}</Tooltip>;
          tags.push(<OverlayTrigger placement="top" overlay={tooltip}>
                    <Label style={{marginLeft: 2, marginRight: 2}}>{property}</Label>
                  </OverlayTrigger>);
        }
      }
      return (
        <div>
          {tags}
        </div>
      )
    };

    let showRows = this.props.Shows.map((Show, n) => {
      let linkToViewUrl = `/shows/${Show._id}`;
      let linkToEditUrl = `/shows/edit/${Show._id}`;
      let formattedShowDate = Show.date.substr(0,10);
      return (
        <tr>
          {auth.loggedIn() &&
          <td>
            <LinkContainer to={linkToEditUrl}>
              <Button bsSize='xs' bsStyle="warning"><i className="fa fa-pencil-square" /></Button>
            </LinkContainer>
          </td>
          }
          <td>{n}</td>
          <td><Link key={Show._id} to={linkToViewUrl}>{formattedShowDate}</Link></td>
          {auth.loggedIn() &&
          <td>
            <UserAttendanceButton showId={Show._id} UserStore={UserStore}
                                  textChecked={Show.date > Date.now() ? "Going" : "Went"}
                                  textUnChecked={Show.date > Date.now() ? "Not Going" : "Didn't Go"}

            />
          </td>
          }
          <td>{Show.venue.name}</td>
          <td>{Show.venue.city}</td>
          <td>{Show.venue.state}</td>
          <td>{renderNotes(Show.notes)}</td>
          <td>{Show.sets.filter((set) => set.name.substr(0,3).toLowerCase() === 'set').length}</td>
        </tr>
      );
    });

    let table = <Table striped condensed hover responsive>
      <thead>
      <tr>
        {auth.loggedIn() &&
        <th></th>
        }
        <th>#</th>
        <th>Date</th>
        {auth.loggedIn() &&
        <th>Attended</th>
        }
        <th>Venue</th>
        <th>City</th>
        <th>State</th>
        <th>Notes</th>
        <th>Sets</th>
      </tr>
      </thead>
      <tbody>
      {showRows}
      </tbody>
    </Table>;


    return (
      <div className="marginBottom">
        <Grid>
          <Row className="productList">
            <h1>Shows
              { auth.loggedIn() &&
                <Link to='/shows/create'>
                  <Button bsStyle="success" style={{marginLeft: 10}} onClick={this.handleAddClick}><i className="fa fa-plus-circle"/></Button>
                </Link>
              }
            </h1>
            <hr/>
            { auth.loggedIn() &&
              <div>
                <h4>You've been to: {showCount} show{showCount !== 1 ? 's' : ''}</h4>
                <hr />
              </div>
            }
            {table}
          </Row>
        </Grid>
      </div>
    );


  }

}
export default ListShowsChild;

import React from 'react';
import {Button} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import {Panel, Well, Table} from 'react-bootstrap';
import {Form, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';

import DateTime from 'react-datetime';

const ticketSources = {
  UNKNOWN: "UNKNOWN",
  TICKETFLY: "TICKETFLY",
  FRONTGATE: "FRONTGATE",
  TICKETWEB: "TICKETWEB",
  TICKETMASTER: "TICKETMASTER",
  EVENTBRITE: "EVENTBRITE",
  AXS: "AXS",
  OTHER: "OTHER"
};
const ticketAvailability = {
  UNAVAILABLE: "UNAVAILABLE",
  PRESALE: "PRESALE",
  ONSALE: "ONSALE",
  COMINGSOON: "COMINGSOON",
  ALMOSTOUT: "ALMOSTOUT",
  SOLDOUT: "SOLDOUT",
  NOONLINE: "NOONLINE",
};

const ticketModes = {
  CHOOSE: "CHOOSE",
  NEW: "NEW",
  EDIT: "EDIT"
};

class EditTickets extends React.Component {
  constructor(props){
    super(props);

    this.handleTicketDateStateChange = this.handleTicketDateStateChange.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);

    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleAvailabilityChange = this.handleAvailabilityChange.bind(this);
    this.handleTicketCodeChange = this.handleTicketCodeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    let epoch = '1970-01-01T00:00:00Z';
    let newTickets = {
      [epoch] : {
        source: ticketSources.UNKNOWN,
        url: '',
        availability: ticketAvailability.UNAVAILABLE
      }
    };

    let tickets = this.reformatOldTickets(this.props.tickets) || newTickets;
    this.state = {
      tickets: tickets,
      selectedDate: Object.keys(tickets)[0],
      ticketMode: ticketModes.CHOOSE
    }
  }

  componentWillReceiveProps(nextProps){
    if (JSON.stringify(this.props.tickets) !== JSON.stringify(nextProps.tickets)){
      const newTickets = this.reformatOldTickets(nextProps.tickets);
      this.setState({
        tickets: newTickets,
        selectedDate: Object.keys(newTickets)[0],
      });
    }
  }

  reformatOldTickets(oldTickets){
    if (oldTickets && oldTickets.source) {
      return {
        [new Date().toISOString().replace(/\.\d{3}Z/,'Z')]: {
          source: oldTickets.source,
          availability: oldTickets.availability,
          url: oldTickets.url,
          code: oldTickets.codes && oldTickets.codes[oldTickets.availability]
        }
      }
    }

    return oldTickets;
  }

  handleTicketDateStateChange(e){
    this.setState({selectedDate: e.target.value})
  }
  handleDeleteClick(){
    const { tickets, selectedDate } = this.state;
    delete tickets[selectedDate];
    const newSelected = Object.keys(tickets)[0];
    this.setState({
      tickets: tickets,
      selectedDate: newSelected,

    });
  }
  handleEditClick() {
    // const { tickets, selectedDate } = this.state;
    this.setState({ticketMode: ticketModes.EDIT})
  }
  handleNewClick() {
    const { tickets } = this.state;
    let now = new Date().toISOString().replace(/\d{2}\.\d{3}Z/,'00Z');
    if (tickets.hasOwnProperty(now)){
      return 0
    }
    tickets[now] = {
      source: ticketSources.UNKNOWN,
      availability: ticketAvailability.UNAVAILABLE,
      url: ""
    };

    this.setState({tickets: tickets, selectedDate: now})
  }

  render() {
    const { tickets, selectedDate, ticketMode } = this.state;
    return ( <Panel header={<h4>Tickets</h4>}>
       <Row controlId="ticketStateDates">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Ticket State Dates (UTC):</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Ticket State Dates (UTC):</ControlLabel></Col>
          <Col sm={10}>
            <Button bsSize="small" bsStyle="success" style={{marginRight: 5}} onClick={this.handleNewClick}>
              <i className="fa fa-plus-circle" />
            </Button>
            <FormControl componentClass="select" placeholder="select" value={selectedDate}  onChange={this.handleTicketDateStateChange} style={{
              width: "60%",
              display: "inline-block",
              marginRight: 5
            }}>
              {
                Object.keys(tickets).sort().map((property) => {
                  return <option key={property} value={property}>{`${new Date(property)}: ${tickets[property].availability}`}</option>
                })
              }
            </FormControl>
            <Button bsSize="small" bsStyle="warning" style={{marginRight: 5}} onClick={this.handleEditClick}>
              <i className="fa fa-edit"/>
            </Button>
            <Button bsSize="small" bsStyle="danger" style={{marginRight: 5}} onClick={this.handleDeleteClick}>
              <i className="fa fa-trash"/>
            </Button>
          </Col>
        </Row>
        {
          ticketMode === ticketModes.CHOOSE
            ? ""
            : this.render1()
        }
      </Panel>
    )
  }

  handleSourceChange(e){
    const { tickets, selectedDate } = this.state;
    tickets[selectedDate].source = e.target.value;
    this.setState({tickets: tickets});
    if (this.props.onChange){
      this.props.onChange(tickets);
    }
  }
  handleUrlChange(e){
    const { tickets, selectedDate } = this.state;
    tickets[selectedDate].url = e.target.value;
    this.setState({tickets: tickets});
    if (this.props.onChange){
      this.props.onChange(tickets);
    }
  }
  handleAvailabilityChange(e){
    const { tickets, selectedDate } = this.state;
    tickets[selectedDate].availability = e.target.value;
    this.setState({tickets: tickets});
    if (this.props.onChange){
      this.props.onChange(tickets);
    }
  }
  handleTicketCodeChange(e){
    const { tickets, selectedDate } = this.state;
    tickets[selectedDate].code = e.target.value;
    this.setState({tickets: tickets});
    if (this.props.onChange){
      this.props.onChange(tickets);
    }
  }
  handleDateChange(val) {
    const { tickets, selectedDate } = this.state;
    if (typeof val !== 'string'){
      let datetime = val.toISOString().replace(/\.\d{3}Z/,'Z');
      if (selectedDate !== datetime){
        tickets[datetime] = Object.assign({},tickets[selectedDate]);
        delete tickets[selectedDate];
        this.setState({tickets: tickets, selectedDate: datetime});
        if (this.props.onChange){
          this.props.onChange(tickets);
        }
      }
    }
  }

  render1(){
    const { tickets, selectedDate, ticketMode } = this.state;
    const currentTickets = tickets[selectedDate] || {};

    return (
      <Row style={{
        paddingTop: 10,
        paddingBottom: 10
      }}>
        <Row>
          <Col xsHidden={true} sm={2} className="text-right">
            <ControlLabel>Ticket Change Local Time</ControlLabel>
          </Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}>
            <ControlLabel>Ticket Change Local Time:</ControlLabel>
          </Col>
          <Col sm={10}>
            <DateTime
              utc={false}
              value={new Date(selectedDate)}
              onChange={this.handleDateChange}
            />
          </Col>
        </Row>
        <FormGroup controlId="ticketSource">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Source:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Source:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl componentClass="select" placeholder="select" value={currentTickets.source}  onChange={this.handleSourceChange}>
              {
                Object.keys(ticketSources).map((property) => {
                  return <option key={property} value={property}>{ticketSources[property]}</option>
                })
              }
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup controlId="ticketAvailability">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Availability:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Availability:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl componentClass="select" placeholder="select" value={currentTickets.availability}  onChange={this.handleAvailabilityChange}>
              {
                Object.keys(ticketAvailability).map((property) => {
                  return <option key={property} value={property}>{ticketAvailability[property]}</option>
                })
              }
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup controlId="ticketCode">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Buy Code</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Buy Code:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl
              id="ticketCode"
              type="text"
              label="Code"
              onChange={this.handleTicketCodeChange}
              value={currentTickets.code}
              placeholder="Enter Ticket Code"/>
          </Col>
        </FormGroup>
        <FormGroup controlId="ticketUrl">
          <Col xsHidden={true} sm={2} className="text-right"><ControlLabel>Url:</ControlLabel></Col>
          <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true}><ControlLabel>Url:</ControlLabel></Col>
          <Col sm={10}>
            <FormControl
              id="ticketUrl"
              type="text"
              label="Url"
              value={currentTickets.url}
              onChange={this.handleUrlChange}
              placeholder="Enter Ticket Url"/>
          </Col>
        </FormGroup>
        <FormGroup style={{textAlign: "right"}} >
          <Button style={{marginTop: 10, marginRight: 20}} bsStyle="success" onClick={() => this.setState({ticketMode: ticketModes.CHOOSE})}>Close</Button>
        </FormGroup>
      </Row>
    );
  }
}

export default EditTickets;